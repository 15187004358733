import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import Gate from "../../data/logo/Gate.jpg";
import Plantinside from "../../data/logo/Plantinside.jpg";
import RawMaterialStorage from "../../data/logo/RawMaterialStorage.jpg";

const Plant = () => {
  return (
    <Container
      maxWidth="md"
      style={{
        display: "flex",
        padding: "10px",
        justifyContent: "space-between",
      }}
    >
      <Card>
        <CardMedia
          component="img"
          alt="Sample Image"
          height="auto"
          image={Gate}
        />
      </Card>
      <Card>
        <CardMedia
          component="img"
          alt="Sample Image"
          height="auto"
          image={Plantinside} // Replace with your image URL
        />
      </Card>
      <Card>
        <CardMedia
          component="img"
          alt="Sample Image"
          height="auto"
          image={RawMaterialStorage} // Replace with your image URL
        />
      </Card>
    </Container>
  );
};

export default Plant;
