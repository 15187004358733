import "./FooterStyles.css";
const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="top">
          <div>
            <h1> NUTRIVET LIFE SCIENCES</h1>
            <p> Welcome To our Site</p>
            <h4>Our Brochure</h4>
            <a
              href="https://drive.google.com/file/d/1if_5AotY8EecXYHKAjQYe_ZYEXV4aNdA/view"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              View / Download
            </a>
          </div>
        </div>

        <div className="bottom">
          <div>
            <h4> Meet Us</h4>
            <a href="/">
              {" "}
              Plot no E-45/7 & 8, MIDC, Kurkumbh, <br />
              Tal- Daund, Dist- Pune. 413802.
            </a>
            <h4> Check Our plant / product</h4>
            <a href="/plant">Visit Images</a>
          </div>
          <div>
            <h4>CONTACT US</h4>
            <a
              href="tel:+918380076765"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              {" "}
              +91 8380076765
            </a>
            <a
              href="tel:+918380066765"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              {" "}
              +91 8380066765
            </a>
            <a
              href="tel:+919822006765"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              {" "}
              +91 9822006765
            </a>
          </div>
          <div>
            <h4>OUR CERTIFICATIONS</h4>
            <a href="/iso"> ISO</a>
            <a href="/udyam"> UDYAM</a>
            <a href="/gst"> GST</a>
          </div>
          <div>
            <h4> OTHERS CONTACT</h4>
            <a
              href="mailto:contact@nutrivet.in?body=Dear, NUTRIVET LIFE SCIENCES"
              style={{ color: "white", textDecoration: "none" }}
            >
              contact@nutrivet.in
            </a>
            <a
              href="mailto:sales@nutrivet.in?body=Dear, NUTRIVET LIFE SCIENCES"
              style={{ color: "white", textDecoration: "none" }}
            >
              sales@nutrivet.in
            </a>
            <a
              href="mailto:nutrivetfeed@yahoo.com?body=Dear, NUTRIVET LIFE SCIENCES"
              style={{ color: "white", textDecoration: "none" }}
            >
              nutrivetfeed@yahoo.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
