// import * as React from "react";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import Divider from "@mui/material/Divider";
// import ListItemText from "@mui/material/ListItemText";
// import Typography from "@mui/material/Typography";
// import { Box, Grid } from "@mui/material";

// import data from "../../data/ServicesData/Data";
// import service from "../../data/ServicesData/Service";

// export default function Services() {
//   return (
//     <Box>
//       <Box
//         sx={{
//           color: "#ed563b",
//           fontWeight: "700",
//           letterSpacing: "2.5px",
//           fontSize: 40,
//           p: 4,
//         }}
//         align="center"
//       ></Box>
//       <Box
//         mx="auto"
//         borderRadius="10px"
//         sx={{
//           boxShadow: "5px 5px 25px rgba(0,0,0,0.2)",
//           width: { lg: "80%" },
//           padding: "20px",
//           flexDirection: { xs: "column", md: "row" },
//           backgroundColor: "#232d39",
//         }}
//       >
//         <Grid
//           container
//           spacing={2}
//           display={"flex"}
//           justifyContent={"space-around"}
//         >
//           <Grid item xs={12} md={6}>
//             <Box
//               sx={{
//                 color: "#ed563b",
//                 fontWeight: "700",
//                 letterSpacing: "2.5px",
//                 fontSize: 40,
//                 p: 4,
//                 textAlign: "center",
//               }}
//             >
//               Product
//             </Box>
//             <List
//               sx={{
//                 width: "100%",
//                 bgcolor: "background.paper",
//               }}
//             >
//               {data.map((item, index) => (
//                 <React.Fragment key={index}>
//                   <ListItem alignItems="flex-start">
//                     <ListItemText
//                       primary={item.commonspecies}
//                       secondary={
//                         <React.Fragment>
//                           <Typography
//                             component="div"
//                             variant="body2"
//                             color="text.primary"
//                           >
//                             Species :- {item.species}
//                           </Typography>
//                           Strain :- {item.strain}
//                         </React.Fragment>
//                       }
//                     />
//                   </ListItem>
//                   {index < data.length - 1 && (
//                     <Divider variant="inset" component="li" />
//                   )}
//                 </React.Fragment>
//               ))}
//             </List>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Box
//               sx={{
//                 color: "#ed563b",
//                 fontWeight: "700",
//                 letterSpacing: "2.5px",
//                 fontSize: 40,
//                 p: 4,
//                 textAlign: "center",
//               }}
//             >
//               Services
//             </Box>
//             <List
//               sx={{
//                 width: "100%",
//                 bgcolor: "background.paper",
//               }}
//             >
//               {service.map((item, index) => (
//                 <React.Fragment key={index}>
//                   <ListItem alignItems="flex-start">
//                     <ListItemText
//                       primary={item.Name}
//                       secondary={
//                         <React.Fragment>
//                           <Typography
//                             component="div"
//                             variant="body2"
//                             color="text.primary"
//                           >
//                             {item.main}
//                           </Typography>
//                         </React.Fragment>
//                       }
//                     />
//                   </ListItem>
//                   {index < data.length - 1 && (
//                     <Divider variant="inset" component="li" />
//                   )}
//                 </React.Fragment>
//               ))}
//             </List>
//           </Grid>
//         </Grid>
//       </Box>
//     </Box>
//   );
// }

//!
import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import logo from "../../data/logo/logo.png";
import Picture1 from "../../data/logo/edited.jpg";
import Picture2 from "../../data/logo/PelletedDietsforRabbit.jpg";
import Picture3 from "../../data/logo/PelletedDietsforGuineaPigs.jpg";
import Picture4 from "../../data/logo/feed.jpg";

const Services = () => {
  const cardstyle = {
    maxWidth: 400,
    height: 300,
    m: "0  auto",
    alignItems: "center",
  };

  return (
    <>
      <Container>
        <Grid container spacing={5} pt={3}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                "& > :not(style)": {
                  width: "100%",
                  maxWidth: 600,
                  p: 5,
                },
              }}
            >
              <Typography variant="h3" sx={{ color: "#3d565d", fontSize: 40 }}>
                <p style={{ color: "red" }}>RAT / MICE PELLETED DIETS</p>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: "#c3d9e0",
                  fontFamily: "sans-serif",
                  fontSize: 18,
                  borderRadius: 2,
                  color: "#364343",
                }}
              >
                <b>RAT/MICE PELLETED DIETS</b> 12 mm Ground Pelleted Feed for
                Rat & Mice ( Rodents). Enriched with essential Amino acids &
                Vitamins.
                <p>
                  <b> Packaging Size :- </b> 25 Kg
                </p>
                <p>
                  <b>Packaging Type :- </b> Paper Bag
                </p>
                <p>
                  <b>Type Of Food :- </b> Pelleted Feed
                </p>
                <p>
                  <b>Pellet Size :- </b> 10 – 12 mm
                </p>
                <p>
                  <b>Shelf Life :- </b> Best before 4 months from the date of
                  manufacturing
                </p>
                <p>
                  <b>Features :- </b> For Breeding and Maintenance
                </p>
                <p>
                  <b>Diet Code :- </b> STD -1020
                </p>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} mt={5}>
            <Card sx={cardstyle}>
              <CardMedia
                sx={{ width: 400, height: 300 }}
                image={Picture1}
                title="green iguana"
              />
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={5} mt={2}>
          <Grid
            item
            xs={12}
            md={6}
            mt={5}
            className="order-last order-md-first"
          >
            <Card sx={cardstyle}>
              <CardMedia
                sx={{ width: 400, height: 300 }}
                image={Picture2}
                title="green iguana"
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                "& > :not(style)": {
                  width: "100%",
                  maxWidth: 700,
                  p: 5,
                },
              }}
            >
              <Typography variant="h3" sx={{ color: "#3d565d", fontSize: 40 }}>
                <p style={{ color: "red" }}> RABBIT PELLETED DIETS</p>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: "#c3d9e0",
                  fontFamily: "sans-serif",
                  fontSize: 18,
                  borderRadius: 2,
                  color: "#364343",
                }}
              >
                4 - 6 mm Ground Pelleted Feed for Rabbits. Enriched with Lucerne
                Grass, essential Amino acids & added Vitamins.
                <p>
                  <b> Packaging Size :- </b> 25 Kg
                </p>
                <p>
                  <b>Packaging Type :- </b> Paper Bag
                </p>
                <p>
                  <b>Moisture :- </b> 10 % Max
                </p>
                <p>
                  <b>Type Of Food :- </b> Pelleted Feed
                </p>
                <p>
                  <b>Shelf Life :- </b> Best before 4 months from the date of
                  manufacturing
                </p>
                <p>
                  <b>Purity :- </b> 100 % Purely Vegetarian
                </p>
                <p>
                  <b>Diet Form :- </b> 4 mm (Gr. Pellets)
                </p>
                <p>
                  <b>Calcium :- </b> 1 %
                </p>
                <p>
                  <b>Crude Fiber :- </b> 12 -18 %
                </p>
                <p>
                  <b>Diet Code :- </b> RB - 1318
                </p>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={5} mt={2}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                "& > :not(style)": {
                  width: "100%",
                  maxWidth: 600,
                  p: 5,
                },
              }}
            >
              <Typography variant="h3" sx={{ color: "#3d565d", fontSize: 40 }}>
                <p style={{ color: "red" }}> GUINEA PIG PELLETED DIETS</p>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: "#c3d9e0",
                  fontFamily: "sans-serif",
                  fontSize: 18,
                  borderRadius: 2,
                  color: "#364343",
                }}
              >
                4 - 6 mm Ground Pelleted Feed for All strains of Guinea Pigs.
                Enriched with Lucerne Grass, essential Amino acids & added
                Vitamins (Especially Vit. C).
                <p>
                  <b>Packaging Size :- </b> 25 Kg
                </p>
                <p>
                  <b>Packaging Type :- </b> Paper Bag
                </p>
                <p>
                  <b>Shelf Life :- </b> Best before 4 months from the date of
                  manufacturing
                </p>
                <p>
                  <b>Moisture :- </b> 10 % Max
                </p>
                <p>
                  <b>Crude Fiber :- </b> 10 to 16 %
                </p>
                <p>
                  <b>Calcium :- </b> 0.85 %
                </p>
                <p>
                  <b> Died Code</b> GP-1420
                </p>
                <p>
                  <b>Diet Form :- </b> 4 to 6 mm (Gr.Pallets)
                </p>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} mt={5}>
            <Card sx={cardstyle}>
              <CardMedia
                sx={{ width: 400, height: 300 }}
                image={Picture3}
                title="green iguana"
              />
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={5} mt={2}>
          <Grid
            item
            xs={12}
            md={6}
            mt={5}
            className=" order-last order-md-first"
          >
            <Grid item xs={12} md={6} mt={5}>
              <Card sx={cardstyle}>
                <CardMedia
                  sx={{ width: 400, height: 300 }}
                  image={Picture4}
                  title="green iguana"
                />
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              sx={{
                "& > :not(style)": {
                  width: "100%",
                  maxWidth: 600,
                  p: 5,
                  marginBottom: "30px",
                },
              }}
            >
              <Typography variant="h3" sx={{ color: "#3d565d", fontSize: 40 }}>
                <p style={{ color: "red" }}>ANIMAL BEDDING</p>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: "#c3d9e0",
                  fontFamily: "sans-serif",
                  fontSize: 18,
                  borderRadius: 2,
                  color: "#364343",
                }}
              >
                <b> Corncob Bedding Material - </b>
                6-8 mm Corncob round Grits, Very Soft material for Bedding of
                all small Animals, although corncob bedding is not a sterile
                material, it is a common bedding choice due to its absorbency,
                biodegradability and ability to control ammonia levels.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Services;
