// import * as React from "react";
// import { useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import MobileStepper from "@mui/material/MobileStepper";
// import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";
// import SwipeableViews from "react-swipeable-views";
// import { autoPlay } from "react-swipeable-views-utils";
// import images from "../../data/homedata/Images";

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// function Home() {
//   const theme = useTheme();
//   const [activeStep, setActiveStep] = React.useState(0);
//   const maxSteps = images.length;

//   const handleStepChange = (step) => {
//     setActiveStep(step);
//   };

//   return (
//     <Box sx={{ maxWidth: "100vw", flexGrow: 1 }}>
//       <Paper
//         square
//         elevation={0}
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           height: 50,
//           pl: 2,
//           bgcolor: "background.default",
//         }}
//       >
//         <Typography>{images[activeStep].label}</Typography>
//       </Paper>
//       <AutoPlaySwipeableViews
//         axis={theme.direction === "rtl" ? "x-reverse" : "x"}
//         index={activeStep}
//         onChangeIndex={handleStepChange}
//         enableMouseEvents
//       >
//         {images.map((step, index) => (
//           <div key={step.label}>
//             {Math.abs(activeStep - index) <= 2 ? (
//               <Box
//                 component="img"
//                 sx={{
//                   height: "80vh",
//                   display: "block",
//                   overflow: "hidden",
//                   width: "100vw",
//                 }}
//                 src={step.imgPath}
//                 alt={step.label}
//               />
//             ) : null}
//           </div>
//         ))}
//       </AutoPlaySwipeableViews>
//       <MobileStepper
//         steps={maxSteps}
//         position="static"
//         activeStep={activeStep}
//       />
//     </Box>
//   );
// }

// export default Home;

//!

import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import slider022 from "../../data/logo/slider022.jpg";
import Hero from "./Hero";
import Picture1 from "../../data/logo/edited.jpg";
import Picture2 from "../../data/logo/PelletedDietsforRabbit.jpg";
import Picture3 from "../../data/logo/PelletedDietsforGuineaPigs.jpg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ServicesPage from "../company/ServicesPage";

const HomePage = () => {
  const navigate = useNavigate();

  const navigateToServices = () => {
    navigate("/products");
  };

  return (
    <div className="position-relative">
      {/* <Hero
        cName="hero-mid"
        heroImg={slider022}
        title=" About"
        btnClass="hide "
      /> */}
      <Container sx={{ marginTop: 10, marginBottom: 10 }}>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Grid item xs={12} md={3}>
            <Card>
              <img height={209} variant="top" src={Picture1} alt="" />
              <CardContent style={{ textAlign: "center", height: "150px" }}>
                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                  RAT / MICE PELLETED DIETS
                </Typography>
                <Typography variant="body2">
                  12 mm Ground Pelleted Feed for Rat & Mice ( Rodents). Enriched
                  with essential Amino acids & Vitamins.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <img height={209} variant="top" src={Picture2} alt="" />
              <CardContent style={{ textAlign: "center", height: "150px" }}>
                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                  RABBIT PELLETED DIETS
                </Typography>
                <Typography variant="body2">
                  4 - 6 mm Ground Pelleted Feed for Rabbits. Enriched with
                  Lucerne Grass, essential Amino acids & added Vitamins.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <img height={209} variant="top" src={Picture3} alt="" />
              <CardContent style={{ textAlign: "center", height: "150px" }}>
                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                  GUINEA PIG PELLETED DIETS
                </Typography>
                <Typography variant="body2">
                  4 - 6 mm Ground Pelleted Feed for All strains of Guinea Pigs.
                  Enriched with Lucerne Grass, essential Amino acids & added
                  Vitamins (Especially Vit. C).
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          <Button onClick={navigateToServices}>MORE</Button>
        </Grid>
        <ServicesPage />
      </Container>
    </div>
  );
};

export default HomePage;
