import React from "react";
import { Grid, Card, CardContent, Typography, Container } from "@mui/material";

const companyData = [
  {
    name: "Prado Preclinical Research Pvt. Ltd.",
  },
  {
    name: "Intox Pvt. Ltd.",
  },
  {
    name: "Serum Institute of India, Pune",
  },
  {
    name: "Bharat Serums and Vaccines Pvt. Ltd.",
  },
  {
    name: "Accuprec Research Labs Pvt. Ltd",
  },
  {
    name: "Isera Biologicals Pvt. Ltd, Sangli",
  },
  {
    name: "Savitribai Phule Pune University, Pune",
  },
  {
    name: "Panacea Biotech Ltd., Lalru, Punjab",
  },
  {
    name: "Bioneeds, Bengluru.",
  },
  {
    name: "Tox India Pvt. Ltd, Pune",
  },
  {
    name: "Indian Institute of Toxicology, Hadapsar",
  },
  {
    name: "BioVaccines India P. Ltd.",
  },
  {
    name: "Crystal Biological Solutions Pvt. Ltd",
  },
  {
    name: "Global Bioresearch Solutions Pvt. Ltd",
  },
  {
    name: "Raut Serums India Pvt. Ltd, Pune",
  },
  {
    name: "Premium Serums and Vaccines Pvt Ltd.",
  },
  {
    name: "Rodent Research India Pvt. Ltd.Haryana",
  },
  {
    name: "Vanta Biosciences Ltd, Chennai",
  },
  {
    name: "Tulip Diagnostics India Pvt. Ltd., Goa.",
  },
  {
    name: "Vaarunya Biolabs Pvt. Ltd, Bengluru",
  },
  {
    name: "AND MANY MORE IN PAN INDIA",
  },
];

const ServicesPage = () => {
  return (
    <Container className="Service component_space" id="Services">
      <h2 style={{ textAlign: "center", color: "red" }}>Our Clients</h2>
      <Grid container spacing={3}>
        {companyData.map((company, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card className="service_box pointer" style={{ height: "100%" }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  <b>{company.name}</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {company.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ServicesPage;
