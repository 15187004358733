import React, { useRef } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import emailjs from "emailjs-com"; // Updated import statement
import { styled } from "@mui/styles";
import { NavLink } from "react-router-dom";

const Contact = () => {
  const EnqLink = styled(NavLink)({
    textDecoration: "none",
    borderRadius: "8px",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    padding: "12px 0",
    backgroundColor: "#ed563b",
    width: "100%",
    fontSize: "18px",
    transition: "all 0.3s",
    textAlign: "center",
    margin: "10px 0px",
    "&:hover": {
      backgroundColor: "#f9735b",
    },
  });

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_o36s1ru",
        "template_mhca2f2",
        form.current,
        "18zrPEDOJ_xcfhZYL"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    message: "",
  };

  const [contactUs, setContactUs] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactUs({ ...contactUs, [name]: value });
  };

  return (
    <Box>
      <Box
        sx={{
          color: "#ed563b",
          fontWeight: "700",
          letterSpacing: "2.5px",
          fontSize: 40,
          p: 4,
        }}
        align="center"
      ></Box>
      <Box
        mx="auto"
        borderRadius="10px"
        sx={{
          boxShadow: "5px 5px 25px rgba(0,0,0,0.2)",
          width: { lg: "80%" },
          padding: "20px",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          backgroundColor: "#232d39",
        }}
      >
        <Box
          marginTop="50px"
          sx={{
            width: {
              md: "50%",
              xs: "100%",
              // backgroundColor: "#232d39",
              color: "white",
            },
          }}
        >
          <h2 align="center">
            Dr. SATISH DATTAJI BHANDE
            <div style={{ color: "#ed563b" }}>Proprietor.</div>
          </h2>

          <Box>
            <Typography marginLeft="10px" align="center" padding="20px">
              Call Us -
              <div>
                <a
                  href="tel:+918380076765"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  {" "}
                  +91 8380076765
                </a>
                ,
                <a
                  href="tel:+918380066765"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  {" "}
                  +91 8380066765
                </a>
                ,
                <a
                  href="tel:+919822006765"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  {" "}
                  +91 9822006765
                </a>
              </div>
              <div></div>
            </Typography>
          </Box>
          <Box sx={{ display: "block" }}>
            <Typography marginLeft="10px" align="center" padding="20px">
              Email Us - <br />
              <div>
                <a
                  href="mailto:contact@nutrivet.in?body=Dear, NUTRIVET LIFE SCIENCES"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  contact@nutrivet.in
                </a>
              </div>
              <div>
                <a
                  href="mailto:sales@nutrivet.in?body=Dear, NUTRIVET LIFE SCIENCES"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  sales@nutrivet.in
                </a>
              </div>
              <div>
                <a
                  href="mailto:nutrivetfeed@yahoo.com?body=Dear, NUTRIVET LIFE SCIENCES"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  nutrivetfeed@yahoo.com
                </a>
              </div>
            </Typography>
          </Box>
          <Box>
            <Typography marginLeft="10px" align="center" padding="20px">
              Plant Address - <br />
              Plot no E-45/7 & 8, MIDC, Kurkumbh, Tal- Daund, Dist- Pune.
              413802.
            </Typography>
          </Box>
        </Box>
        <Card
          sx={{
            maxWidth: 450,
            marginLeft: "5px",
            padding: "5px 5px",
          }}
        >
          <CardContent>
            <Typography gutterBottom varient="h4" color="#ed563b">
              Get in touch with us:
            </Typography>
            <Typography
              gutterBottom
              color="textSecondary"
              varient="body2"
              component="p"
            >
              Fill up the form and our team will get back to you in 24 hours.
            </Typography>

            <form ref={form} onSubmit={sendEmail}>
              <Grid container spacing={1} sx={{ color: "white" }}>
                <Grid xs={12} item>
                  <TextField
                    label="Name"
                    placeholder="name"
                    variant="outlined"
                    name="name"
                    value={contactUs.name}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid xs={12} item>
                  <TextField
                    type="email"
                    label="Email"
                    placeholder="Enter email"
                    variant="outlined"
                    name="email"
                    value={contactUs.email}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    label="Message"
                    multiline
                    rows={4}
                    placeholder="Enter message"
                    variant="outlined"
                    name="message"
                    value={contactUs.message}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid xs={12} item sx={{ display: "flex" }}>
                  <Button type="submit" variant="contained" color="primary">
                    Send Enquiry
                    <SendIcon sx={{ margin: "0 auto", color: "white" }} />
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
      <div style={{ margin: "20px" }}>{/* <GoogleMapsLocation /> */}</div>
    </Box>
  );
};

export default Contact;
