import React from "react";

import Home from "../../features/frontend/content/Home";
import Services from "../../features/frontend/content/Services";
import About from "../../features/frontend/content/About";
import Conatact from "../../features/frontend/content/Contact";
import PdfViewer from "../../features/frontend/content/Iso";
import Gst from "../../features/frontend/content/Gst";
import Udyam from "../../features/frontend/content/Udyam";
import Plant from "../../features/frontend/content/Plant";

export default [
  {
    label: "Home",
    path: "",
    showInMenu: true,
    addRoute: true,
    component: <Home />,
    authenticated: "no",
  },
  {
    label: "Products",
    path: "products",
    showInMenu: true,
    addRoute: true,
    component: <Services />,
    authenticated: "no",
  },
  {
    label: "About",
    path: "about",
    showInMenu: true,
    addRoute: true,
    component: <About />,
    authenticated: "no",
  },
  {
    label: "Contact",
    path: "contact",
    showInMenu: true,
    addRoute: true,
    component: <Conatact />,
    authenticated: "no",
  },
  {
    // label: "Pdf",
    path: "iso",
    showInMenu: false,
    addRoute: true,
    component: <PdfViewer />,
    authenticated: "no",
  },
  {
    // label: "Gust",
    // path: "gst",
    showInMenu: false,
    addRoute: true,
    component: <Gst />,
    authenticated: "no",
  },
  {
    // label: "udyam",
    path: "udyam",
    showInMenu: false,
    addRoute: true,
    component: <Udyam />,
    authenticated: "no",
  },
  {
    // label: "udyam",
    path: "plant",
    showInMenu: false,
    addRoute: true,
    component: <Plant />,
    authenticated: "no",
  },
];
