import React from "react";
import Header from "./Header";
import Footer from "../../features/frontend/content/Footer";
import logo from "../../features/data/logo/logo.png";
import { Grid } from "@mui/material";

const BlankLayout = () => {
  return (
    <>
      <Grid>
        <img
          src={logo}
          alt=""
          style={{ width: "100%", maxHeight: "500px", marginTop: "70px" }}
        />
      </Grid>
      <Header />
      <Footer />
    </>
  );
};

export default BlankLayout;
