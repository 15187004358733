// import * as React from "react";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import Divider from "@mui/material/Divider";
// import ListItemText from "@mui/material/ListItemText";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import Avatar from "@mui/material/Avatar";
// import Typography from "@mui/material/Typography";
// import { Box, Grid } from "@mui/material";
// import LabelImportantIcon from "@mui/icons-material/LabelImportant";
// import vision from "../../data/AboutData/Vision";
// import welcome from "../../data/AboutData/Welcome";
// import data from "../../data/AboutData/DataAbout";
// import service from "../../data/AboutData/Service";

// export default function About() {
//   return (
//     <Box>
//       <Box
//         sx={{
//           color: "#ed563b",
//           fontWeight: "700",
//           letterSpacing: "2.5px",
//           fontSize: 40,
//           p: 4,
//         }}
//         align="center"
//       ></Box>
//       <Box
//         mx="auto"
//         borderRadius="10px"
//         sx={{
//           boxShadow: "5px 5px 25px rgba(0,0,0,0.2)",
//           width: { lg: "80%" },
//           padding: "20px",
//           flexDirection: { xs: "column", md: "row" },
//           backgroundColor: "#232d39",
//         }}
//       >
//         <Grid
//           container
//           spacing={2}
//           display={"flex"}
//           justifyContent={"space-around"}
//         >
//           <Grid item xs={12} md={6}>
//             <Box
//               sx={{
//                 color: "#ed563b",
//                 fontWeight: "700",
//                 letterSpacing: "2.5px",
//                 fontSize: 40,
//                 p: 4,
//                 textAlign: "center",
//               }}
//             >
//               Welcome To Our Company
//             </Box>
//             <List
//               sx={{
//                 width: "100%",
//                 bgcolor: "background.paper",
//               }}
//             >
//               {welcome.map((item, index) => (
//                 <React.Fragment key={index}>
//                   <ListItem alignItems="flex-start">
//                     <ListItemText
//                       primary={item.commonspecies}
//                       secondary={
//                         <React.Fragment>
//                           <Typography
//                             component="div"
//                             variant="body2"
//                             color="text.primary"
//                             sx={{ fontSize: "16px" }}
//                           >
//                             <LabelImportantIcon /> {item.species}
//                           </Typography>
//                           <Box style={{ padding: "10px" }} />
//                           <Typography
//                             component="div"
//                             variant="body2"
//                             color="text.primary"
//                             sx={{ fontSize: "16px" }}
//                           >
//                             <LabelImportantIcon /> {item.message}
//                           </Typography>
//                         </React.Fragment>
//                       }
//                     />
//                   </ListItem>
//                   {index < data.length - 1 && (
//                     <Divider variant="inset" component="li" />
//                   )}
//                 </React.Fragment>
//               ))}
//             </List>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Box
//               sx={{
//                 color: "#ed563b",
//                 fontWeight: "700",
//                 letterSpacing: "2.5px",
//                 fontSize: 40,
//                 p: 4,
//                 textAlign: "center",
//               }}
//             >
//               About Company
//             </Box>
//             <List
//               sx={{
//                 width: "100%",
//                 bgcolor: "background.paper",
//               }}
//             >
//               {data.map((item, index) => (
//                 <React.Fragment key={index}>
//                   <ListItem alignItems="flex-start">
//                     <ListItemText
//                       primary={item.commonspecies}
//                       secondary={
//                         <React.Fragment>
//                           <Typography
//                             component="div"
//                             variant="body2"
//                             color="text.primary"
//                             sx={{ fontSize: "16px" }}
//                           >
//                             <LabelImportantIcon /> {item.species}
//                           </Typography>
//                         </React.Fragment>
//                       }
//                     />
//                   </ListItem>
//                   {index < data.length - 1 && (
//                     <Divider variant="inset" component="li" />
//                   )}
//                 </React.Fragment>
//               ))}
//             </List>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Box
//               sx={{
//                 color: "#ed563b",
//                 fontWeight: "700",
//                 letterSpacing: "2.5px",
//                 fontSize: 40,
//                 p: 4,
//                 textAlign: "center",
//               }}
//             >
//               Our Vision
//             </Box>
//             <List
//               sx={{
//                 width: "100%",
//                 bgcolor: "background.paper",
//               }}
//             >
//               {vision.map((item, index) => (
//                 <React.Fragment key={index}>
//                   <ListItem alignItems="flex-start">
//                     <ListItemAvatar>
//                       <Avatar alt={item.name} src={item.avatarSrc} />
//                     </ListItemAvatar>
//                     <ListItemText primary={item.primaryText} />
//                   </ListItem>
//                   {index < data.length - 1 && (
//                     <Divider variant="inset" component="li" />
//                   )}
//                 </React.Fragment>
//               ))}
//             </List>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Box
//               sx={{
//                 color: "#ed563b",
//                 fontWeight: "700",
//                 letterSpacing: "2.5px",
//                 fontSize: 40,
//                 p: 4,
//                 textAlign: "center",
//               }}
//             >
//               What We Do ??
//             </Box>
//             <List
//               sx={{
//                 width: "100%",
//                 bgcolor: "background.paper",
//               }}
//             >
//               {service.map((item, index) => (
//                 <React.Fragment key={index}>
//                   <ListItem alignItems="flex-start">
//                     <ListItemText
//                       primary={item.Name}
//                       secondary={
//                         <React.Fragment>
//                           <Typography
//                             component="div"
//                             variant="body2"
//                             color="text.primary"
//                           >
//                             <LabelImportantIcon /> {item.main}
//                           </Typography>
//                         </React.Fragment>
//                       }
//                     />
//                   </ListItem>
//                   {index < data.length - 1 && (
//                     <Divider variant="inset" component="li" />
//                   )}
//                 </React.Fragment>
//               ))}
//             </List>
//           </Grid>
//         </Grid>
//       </Box>
//     </Box>
//   );
// }

import React from "react";
import { Container, Typography, Paper, Grid } from "@mui/material";
import mousr from "../../data/logo/mousr.jpeg";
import Box from "@mui/material/Box";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import { IconButton } from "@mui/material";

const About = () => {
  return (
    <Box>
      {/* title */}
      <Typography
        variant="h2"
        style={{ marginTop: "16px", textAlign: "center" }}
      >
        NUTRIVET LIFE SCIENCES
      </Typography>
      <h4 style={{ marginTop: "16px", textAlign: "center" }}>
        LABORATORY ANIMAL DIETS AND MANUFACTURING AND SUPPLIER
      </h4>
      <hr
        style={{
          borderTop: "2px solid red",
          width: "20%",
          margin: "16px auto",
        }}
      />

      {/* Text content in a Grid */}
      <Container
        fixed
        style={{ backgroundColor: "pink", marginTop: "16px", padding: "10px" }}
      >
        <Grid item xs={12} md={6}>
          <p style={{ marginTop: "16px" }}>
            NUTRIVET LIFE SCIENCES is a Proprietary company Established in 2011
            by Dr. Satish Bhande with his 27 Years of experience in the field of
            Laboratory animal science and Research. We are the SINGLE company in
            INDIA who is producing Laboratory Animal feed in a Dedicated plant.
            Dr. Bhande is Veterinarian by Profession and the NUTRIVET LIFE
            SCIENCES – Lab Animal Feed producing company.
          </p>
        </Grid>
        <Grid item xs={12} md={6}>
          <p style={{ marginTop: "16px" }}>
            NUTRIVET LIFE SCIENCES is Established in the year{" "}
            <b>2017 at Pune</b>,<b> Maharashtra</b>, We{" "}
            <b>"Nutrivet Life Sciences"</b> are as <b>Sole Proprietorship</b>{" "}
            based firm, engaged as the foremost Authorized <b>Manufacturer</b>{" "}
            and{" "}
            <b>
              Supplier of Laboratory Animal Diets, Animal Pellet Feed and Animal
              Bedding Material.
            </b>
          </p>
        </Grid>

        <Grid container style={{ marginTop: "10px" }} spacing={3}>
          <Grid item xs={12} md={6}>
            <p>
              Considering importance of laboratory Toxicology studies and
              Researches, we Claim That We Are the Only Company Making
              Laboratory Animal Feed Dedicatedly in Dedicated Plant located
              around 90 Kms from Pune in Own premises having better connectivity
              of Infrastructure and logistics.
            </p>
            <p>
              Natural, Best Quality Grains, Raw Material is used to make feed
              pellets. Balanced Diets for constant Nutrition.
            </p>

            <p>
              Fortified with required Amino acids, Vitamins and Minerals. Purely
              Vegetarian Diets, No Animal Protein and Alfalfa.
            </p>

            <p>
              Superior Quality Raw Material Selection and regular analysis at
              Third Party Labs for Quality Assurance.
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <p>
              Packaging of Pelleted feed is done in Double Layered Paper HDPE
              Sacs
            </p>
            <p>Following are our certifications and registrations :</p>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <ArrowRightIcon style={{ marginLeft: "-35px" }} />
                ISO
              </li>

              <li>
                <ArrowRightIcon style={{ marginLeft: "-35px" }} />
                UDYAM
              </li>

              <li>
                <ArrowRightIcon style={{ marginLeft: "-35px" }} />
                GST
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container style={{ marginBottom: "30px" }}>
        <Grid container style={{ marginTop: "16px" }} spacing={2}>
          <Grid item xs={12} md={4}>
            <h3 style={{ textAlign: "center" }}>Mission</h3>
            <p>
              We NUTRIVET LIFE SCIENCES, Wants to be Leading Manufacturer and
              Supplier of Laboratory Animal Diets with considering value of
              quality and importance of Constant Nutrition in research studies
              for better results.
            </p>
          </Grid>

          <Grid item xs={12} md={4}>
            <img
              src={mousr}
              style={{ width: "100%", height: "auto", borderRadius: "100%" }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <h3 style={{ textAlign: "center" }}>Vision</h3>
            <p>
              To lead in feed quality, innovation and skills to manufacturing
              and Supply of Laboratory Animal Diets at affordable prices for its
              customers. To be recognized as most trustworthy and consistent
              company by users, employees, It believes in the cultivation of
              mutual relationships based on honesty and reliability.
            </p>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;
